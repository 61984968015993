(function () {
	'use strict';

	angular
	.module('core')
	.controller('loginCtrl', loginCtrl);

	loginCtrl.$inject = ['$http', '$scope', '$rootScope', '$route', '$q', '$timeout', 'preloader'];

	function loginCtrl($http, $scope, $root, $route, $q, $timeout, preloader) {
		var vm = $scope;

		$root.route = $route;
		vm.pin = '';
		vm.loginError = false;

		vm.login = function(){
			$http.post("./services/login.php", {"pin": vm.pin}).then(function(response) {
				if(response.data == "true"){
					$root.loggedIn = true;
					$root.go('/home');
				}
				else {
					vm.loginError = true;
					$timeout(function(){
						vm.loginError = false;
					}, 3000);
					$root.loggedIn = false;
				}
			});
		}

		vm.checkEnter = function(e){
			if(e.key == 'Enter'){
				vm.login();
			}
		}

		vm.init = function() {
			if($root.loggedIn == true){
				$root.go('/home');
			}
			else {
				$http.get("./services/login.php").then(function(response) {
					if(response.data == "true"){
						$root.loggedIn = true;
						$root.go('/home');
					}
					else {
						$root.loggedIn = false;
					}
				});
			}
		}

		vm.init();
	}
})();