(function () {
    'use strict';

    angular
    .module('core')
    .directive('autocomplete', autocomplete);

    autocomplete.$inject = ['$sce', '$rootScope', '$timeout'];

    function autocomplete($sce, $root, $timeout) {
        return {
            restrict: 'EA',
            transclude: true,
            templateUrl: './templates/directives/autocomplete.html',
            scope:{
                'model':'=',
                'options':'=?',
                'placeholder':'@?',
                'direction':'@?'
            },
            link: function($scope, elem, attr) {
                var vm = $scope;

                jQuery.fn.scrollTo = function(elem) {
                    $(this).scrollTop($(this).scrollTop() - $(this).offset().top + $(elem).offset().top); 
                    return this; 
                };

                vm.list = [];

                vm.set = function(item){
                	vm.model = item;
                	vm.list = [];
                	delete vm.selectedItem;
                    if(vm.options != undefined && vm.options.onSelect != undefined){
                        vm.options.onSelect();
                    }
                }

                vm.checkClear = function(){
                    $timeout(function(){
                        vm.list = [];

                        if(vm.model == ''){
                            vm.clear();
                        }
                    }, 250);
                }

                vm.clear = function(){
                	vm.list = [];
                    $timeout(function(){
                        delete vm.selectedItem;

                        if(vm.options != undefined && vm.options.onSelect != undefined){
                            vm.options.onSelect();
                        }
                    }, 250);
                }

                vm.check = function(event){
                    var _changed = false;

                	switch(event.key){
                        case 'ArrowDown':
                        event.preventDefault();
                        if(vm.selectedItem == undefined){
                            vm.selectedItem = 0;
                            _changed = true;
                        }
                        else {
                            var _next = vm.selectedItem + 1;
                            if(_next >= Object.keys(vm.list).length) {
                                delete vm.selectedItem;
                            }
                            else {
                                vm.selectedItem = _next;
                                _changed = true;
                            }
                        }
                        break;

                        case 'ArrowUp':
                        event.preventDefault();
                        if(vm.selectedItem == undefined){
                            vm.selectedItem = Object.keys(vm.list).length - 1;
                            _changed = true;
                        }
                        else {
                            var _next = vm.selectedItem - 1;
                            if(_next < 0) {
                                delete vm.selectedItem;
                            }
                            else {
                                vm.selectedItem = _next;
                                _changed = true;
                            }
                        }
                        break;

                        case 'Enter':
                        if(vm.selectedItem != undefined){
                            var _key = vm.list[vm.selectedItem];
                            vm.set(_key);
                            event.target.blur();
                        }
                        else {
                            vm.set(vm.model);
                        }
                        break;

                        default:
                        $timeout(function(){
	                		if(vm.model != undefined && vm.model.length >= vm.options.minimumChars){
	                			vm.list = vm.options.data(vm.model);
	                		}
	                		else {
	                			vm.list = [];
	                		}
	                	});
                    }

                    if(_changed){
                        $timeout(function(){
                            var _panel = $(event.target).parents('.autocomplete');
                            var _list = _panel.find('.autocomplete-list');
                            if(vm.selectedItem != undefined){
                                var _item = _list.find('li')[vm.selectedItem];
                                if(_item != undefined){
                                    _list.scrollTo(_item);
                                }
                            }
                        })
                    }
                }
			}
        }
    }
})();