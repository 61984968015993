(function () {
	'use strict';

	angular
	.module('core')
	.directive('focusIf', focusIf);

	focusIf.$inject = ['$sce', '$timeout'];

	function focusIf($sce, $timeout) {
		return {
			restrict: 'A',
			scope: false,
			link: function($scope, elem, attrs) {
				$scope.$watch(attrs.focusIf, function(value) {
					if(value === true) {
						$timeout(function(){
							if($(elem).prop("tagName") == 'INPUT'){
								elem[0].focus();
							}
							else {
								$(elem).find('input').focus();
							}
						}, 50);
					}
				});
			}
		}
	}
})();