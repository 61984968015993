(function () {
	'use strict';

	angular
	.module('core')
	.controller('teamsCtrl', teamsCtrl);

	teamsCtrl.$inject = ['$http', '$scope', '$rootScope', '$route', '$q', '$timeout', 'preloader'];

	function teamsCtrl($http, $scope, $root, $route, $q, $timeout, preloader) {
		var vm = $scope;

		vm.opponent = '';

		vm.newTeam = function(){
			if(vm.opponent != ''){
				var _data = {
					'team':parseInt(vm.team),
					'opponent':vm.opponent
				}
				$http.post("./services/game.php", _data).then(function(response) {
					vm.game = response.data;
					$root.go('/game/'+vm.game);
				});
			}
		}

		vm.removeTeam = function(id){
			var _data = {
				'game':parseInt(id),
				'remove':true
			}
			$http.post("./services/game.php", _data).then(function(response) {
				vm.games = response.data;
			});
		}

		vm.init = function() {
			$http.get("./services/login.php").then(function(response) {
				if(response.data == "true"){
					$root.loggedIn = true;
				}
				else {
					$root.loggedIn = false;
				}
			});
			
			$http.get("./services/teams.php").then(function(response) {
		        vm.teams = response.data;
		    });
		}

		vm.init();
	}
})();