(function () {
	'use strict';

	angular
	.module('core')
	.controller('gameCtrl', gameCtrl);

	gameCtrl.$inject = ['$http', '$scope', '$rootScope', '$route', '$q', '$timeout', 'preloader'];

	function gameCtrl($http, $scope, $root, $route, $q, $timeout, preloader) {
		var vm = $scope;

		vm.hidden = [];
		vm.editOpponent = false;

		vm.activeCount = function(){
			var _male = 0;
			var _female = 0;

			if(vm.game != undefined){
				angular.forEach(vm.game.players, function(player){
					if(vm.hidden.indexOf(player.id) == -1){
						if(player.gender == 'male'){
							_male = _male + 1;
						}
						else if(player.gender == 'female'){
							_female = _female + 1;
						}
					}
				});
			}

			return _male + ':' + _female;
		}

		vm.changeOpponent = function(){
			vm.tmpOpponent = angular.copy(vm.game.opponent);
			vm.editOpponent = true;
		}

		vm.saveOpponent = function(){
			if(vm.tmpOpponent != vm.game.opponent){
				var _data = {
					'game':parseInt(vm.game.id),
					'update':true,
					'opponent':vm.tmpOpponent
				}
				$http.post("./services/game.php", _data).then(function(response) {
					vm.game = response.data;
					vm.editOpponent = false;
				});
			}
			else {
				vm.editOpponent = false;
			}
		}

		vm.isHidden = function(_player){
			return vm.hidden.indexOf(_player.id) >= 0
		}
		vm.isShown = function(_player){
			return vm.hidden.indexOf(_player.id) == -1
		}

		vm.togglePlayer = function(_player){
			if(vm.hidden.indexOf(_player) >= 0){
				vm.hidden.splice(vm.hidden.indexOf(_player), 1);
			}
			else {
				vm.hidden.push(_player);
			}
		}

		vm.teamScore = function(){
			var _score = 0;
			if(vm.game != undefined && vm.game.stats != undefined){
				angular.forEach(vm.game.stats, function(stat){
					if(parseInt(stat.player) != 0 && stat.type == 'score'){
						_score = _score + 1;
					}
				});
			}
			return _score;
		}

		vm.statAdd = function(_player, _stat){
			var _data = {
				'game':parseInt(vm.game.id),
				'player':parseInt(_player.id),
				'type':_stat
			}
			$http.post("./services/stat.php", _data).then(function(response) {
				vm.game = response.data;
			});
		}
		vm.statRemove = function(_player, _stat){
			var _data = {
				'game':parseInt(vm.game.id),
				'player':parseInt(_player.id),
				'type':_stat,
				'remove':true
			}
			$http.post("./services/stat.php", _data).then(function(response) {
				vm.game = response.data;
			});
		}

		vm.opponentScore = function(){
			var _score = 0;
			if(vm.game != undefined && vm.game.stats != undefined){
				angular.forEach(vm.game.stats, function(stat){
					if(parseInt(stat.player) == 0 && stat.type == 'score'){
						_score = _score + 1;
					}
				});
			}
			return _score;
		}

		vm.getStat = function(_player, _stat){
			var _count = 0;
			if(vm.game != undefined && vm.game.stats != undefined){
				angular.forEach(vm.game.stats, function(stat){
					if(parseInt(stat.player) == parseInt(_player.id) && _stat == stat.type){
						_count = _count + 1;
					}
				});
			}
			return _count;
		}

		vm.stats = [
			'turn',
			'foul',
			'defense',
			'assist',
			'score'
		];

		vm.init = function() {
			$http.get("./services/login.php").then(function(response) {
				if(response.data == "true"){
					$root.loggedIn = true;
				}
				else {
					$root.loggedIn = false;
				}
			});
			if($route.current.params != undefined && $route.current.params.id != undefined){
	    		var _id = $route.current.params.id;
	    		$http.get("./services/game.php?game="+_id).then(function(response) {
			        vm.game = response.data;
			        angular.forEach(vm.game.players, function(player){
			        	vm.hidden.push(player.id);
			        });
			    });
	    	}
		}

		vm.init();
	}
})();