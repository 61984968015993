(function () {
	'use strict';

	angular
	.module('core')
	.controller('playersCtrl', playersCtrl);

	playersCtrl.$inject = ['$http', '$scope', '$rootScope', '$route', '$q', '$timeout', 'preloader'];

	function playersCtrl($http, $scope, $root, $route, $q, $timeout, preloader) {
		var vm = $scope;

		vm.opponent = '';
		vm.currentStat = 'id';
		vm.reverse = true;

		vm.newPlayer = function(){
			
		}

		vm.setStat = function(stat){
			if(vm.currentStat == 'stat_'+stat){
				vm.reverse = !vm.reverse;
			}
			else {
				vm.currentStat = 'stat_'+stat;
				vm.reverse = true;
			}
		}

		vm.getStat = function(_player, _stat){
			var _count = 0;
			if(_player.stats != undefined){
				angular.forEach(_player.stats, function(stat){
					if(_stat == stat.type){
						_count = _count + 1;
					}
				});
			}
			return _count;
		}

		vm.stats = [
			'turn',
			'foul',
			'defense',
			'assist',
			'score'
		];

		vm.updatePlayers = function(){
			$timeout(function(){
				var url = "./services/players.php";
				if($root.team != undefined){
					url = "./services/players.php?team=" + $root.team;
				}
				$http.get(url).then(function(response) {
			        vm.players = response.data;
			        angular.forEach(vm.players, function(player){
			        	angular.forEach(vm.stats, function(stat){
			        		if(player['stat_'+stat] == undefined){
			        			player['stat_'+stat] = 0;
			        		}
			        	})
			        })
			    });
			}, 50);
		}

		vm.init = function() {
			vm.updatePlayers();
		}

		vm.init();
	}
})();