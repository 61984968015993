(function () {
	'use strict';

	angular
	.module('core')
	.controller('baseCtrl', baseCtrl);

	baseCtrl.$inject = ['$http', '$scope', '$rootScope', '$route', '$q', '$timeout', 'preloader'];

	function baseCtrl($http, $scope, $root, $route, $q, $timeout, preloader) {
		var vm = $scope;

		$root.route = $route;

		vm.init = function() {
			$http.get("./services/login.php").then(function(response) {
				if(response.data == "true"){
					$root.loggedIn = true;
					$http.get("./services/teams.php").then(function(response) {
						$root.teams = response.data;
					});
				}
				else {
					$root.loggedIn = false;
				}
			});
		}

		vm.init();
	}
})();