(function () {
	'use strict';

	angular
	.module('core')
	.controller('homeCtrl', homeCtrl);

	homeCtrl.$inject = ['$http', '$scope', '$rootScope', '$route', '$q', '$timeout', 'preloader'];

	function homeCtrl($http, $scope, $root, $route, $q, $timeout, preloader) {
		var vm = $scope;

		vm.logout = function() {
			var _data = {
				'logout':true
			}
			$http.post("./services/login.php", _data).then(function(response) {
				if(response.data != "true"){
					$root.loggedIn = false;
				}
			});
		}

		vm.init = function() {
			$http.get("./services/login.php").then(function(response) {
				if(response.data == "true"){
					$root.loggedIn = true;
				}
				else {
					$root.loggedIn = false;
				}
			});
		}

		vm.init();
	}
})();