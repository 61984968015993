(function () {
    'use strict';

    angular
    .module('core')
    .directive('vector', vector);

    vector.$inject = ['$sce', '$rootScope', '$timeout'];

    function vector($sce, $root, $timeout) {
        return {
            restrict: 'E',
            replace: true,
            template: '<div class="svg" ng-include="getTemplateUrl()"/>',
            scope:{
                'src':'@'
            },
            controller: function($scope) {
				$scope.getTemplateUrl = function(){
                    var _url = $scope.src;
					return _url;
				}
            }
        }
    }
})();