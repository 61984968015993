(function () {
	'use strict';

	angular
	.module('core')
	.filter('custom', function custom(){
		return function(input, search) {
			if (!input) return input;
			if (!search) return input;

			var result = {};
			angular.forEach(input, function(value, key) {
				if(search(value)){
					result[key] = value;
				}
			});
			return result;
		}
	});

	

})();